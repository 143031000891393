<template>
  <el-dialog
    :title="!dataForm.id ? '新增' : !disabled ? '修改' : '查看'"
    :close-on-click-modal="false"
    :visible.sync="visible"
  >
    <el-form
      ref="dataForm"
      :model="dataForm"
      :rules="dataRule"
      label-width="80px"
      @keyup.enter.native="dataFormSubmit()"
    >
      <el-form-item label="用户昵称" prop="userId">
        <el-input
          v-model="dataForm.nickname"
          :disabled="disabled"
          placeholder="用户昵称"
        />
      </el-form-item>
      <el-form-item label="提现类型" prop="withdrawType">
        <el-radio-group v-model="dataForm.withdrawType" :disabled="disabled">
          <el-radio :label="1"> 零钱 </el-radio>
          <el-radio :label="2"> 银行卡 </el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="提现金额" prop="withdrawAmount">
        <el-input
          v-model="dataForm.withdrawAmount"
          :disabled="disabled"
          placeholder="提现金额"
        />
      </el-form-item>
      <el-form-item label="手续费" prop="withdrawFee">
        <el-input
          v-model="dataForm.withdrawFee"
          :disabled="disabled"
          placeholder="手续费"
        />
      </el-form-item>
      <el-form-item label="提现时间" prop="withdrawTime">
        <el-input
          v-model="dataForm.withdrawTime"
          :disabled="true"
          placeholder="提现时间"
        />
      </el-form-item>
      <el-form-item label="审核状态" prop="auditStatus">
        <el-radio-group v-model="dataForm.auditStatus" :disabled="disabled">
          <el-radio :label="0"> 待审核 </el-radio>
          <el-radio :label="1"> 审核通过 </el-radio>
          <el-radio :label="2"> 审核未通过 </el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="审核说明" prop="auditDesc">
        <el-input
          v-model="dataForm.auditDesc"
          :disabled="disabled"
          placeholder="审核说明"
        />
      </el-form-item>
      <el-form-item label="银行卡号" prop="cardNumber">
        <el-input
          v-model="dataForm.cardNumber"
          :disabled="disabled || dataForm.withdrawType === 1"
          placeholder="银行卡号"
        />
      </el-form-item>
      <el-form-item label="银行卡类型" prop="cardType">
        <el-input
          v-model="dataForm.cardType"
          :disabled="disabled || dataForm.withdrawType === 1"
          placeholder="银行卡类型"
        />
      </el-form-item>
      <el-form-item label="银行名称" prop="bankName">
        <el-input
          v-model="dataForm.bankName"
          :disabled="disabled || dataForm.withdrawType === 1"
          placeholder="银行名称"
        />
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取消</el-button>
      <el-button v-if="!disabled" type="primary" @click="dataFormSubmit()"
        >确定</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      disabled: false,
      visible: false,
      dataForm: {
        id: '',
        userId: '',
        nickname: '',
        orderId: '',
        withdrawType: '',
        withdrawAmount: '',
        withdrawFee: '',
        withdrawTime: '',
        auditStatus: '',
        auditDesc: '',
        cardNumber: '',
        cardType: '',
        bankName: '',
      },
      dataRule: {
        name: [{ required: true, message: '名称不能为空', trigger: 'blur' }],
      },
    };
  },
  methods: {
    init(id, disabled) {
      this.disabled = disabled;
      this.dataForm.id = id || '';
      this.visible = true;
      this.$nextTick(() => {
        this.$refs['dataForm'].resetFields();
        if (this.dataForm.id) {
          this.$http({
            url: `/mall/distwithdraw/info/${this.dataForm.id}`,
            method: 'get',
          }).then(({ data }) => {
            if (data && data.code === 0) {
              this.dataForm = data.distwithdraw;
            }
          });
        }
      });
    },
    // 表单提交
    dataFormSubmit() {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          this.$http({
            url: `/mall/distwithdraw/${!this.dataForm.id ? 'save' : 'update'}`,
            method: 'post',
            data: this.dataForm,
          }).then(({ data }) => {
            if (data && data.code === 0) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1500,
              });
              this.visible = false;
              this.$emit('refreshDataList');
            }
          });
        }
      });
    },
  },
};
</script>
